import moment from "moment";

const DEFAULT_COLUMN_META = { sort: null, visible: true };
const DEFAULT_META = {
    start: 0,
    length: 10,
    search: "",
    searchMode: "full",
    sort: false
};
const ACTIVE_LABEL = "Active";
const EXPIRED_DATE_LABEL = "Expired Date";
const DEFAULT_SCENARIO_NAME = "Default Scenario";

var tableKey;
var tableId;
var dbTable;
var defaultScenarios = [
    {
        name: DEFAULT_SCENARIO_NAME,
        active: true,
        edit: true,
        filters: [],
        id:null
    }
];

export const init = (id, table) => {
    tableId = id;
    tableKey = `VueTable_${id}_preferences`;
    dbTable = table;
    defaultScenarios[0].filters = [];

    if(dbTable === 'vendor_offers' || dbTable === 'client_requests' || dbTable === 'pricings'){
        defaultScenarios[0].filters.push({
            label: ACTIVE_LABEL,
            data: `${dbTable}.is_active`,
            mode: null,
            value: true,
            type: "boolean"
        });

        let expireDateTable = dbTable;

        switch (dbTable) {
            case 'vendor_offers':
                expireDateTable = 'vendor_offer_sets';
                break;
            case 'client_requests':
                expireDateTable = 'client_request_sets';
                break;
            case 'pricings':
                expireDateTable = 'pricings';
                break;
        }
        defaultScenarios[0].filters.push({
            label: EXPIRED_DATE_LABEL,
            data: `${expireDateTable}.expire_date`,
            mode: "interval",
            type: "date",
            value: {
                min: moment().format("YYYY-MM-DD"),
                max: moment()
                    .add(1, "month")
                    .format("YYYY-MM-DD")
            }
        });
}

};

export const getState = (table) => {
    let t = table || tableKey;
    var state = JSON.parse(window.localStorage.getItem(t));
    return state || {};
};

export const getTableId = () => {
    return tableId;
};

export const setState = (state,table) => {
    let t = table || tableKey;
    window.localStorage.setItem(t, JSON.stringify(state));
};

export const setDefaultState = initial => {

    setFilters(
        {
            filterScenarios: [],
            meta: DEFAULT_META,
            columns: []
        },
        initial
    );
};

export const hasState = () => {
    var state = getState();
    return Object.keys(state).length > 0;
};

export const getFilters = () => {
    var state = getState();
    var filters = {
        columns: state.columns,
        filterScenarios: state.filterScenarios,
        meta: state.meta
    };
    return filters;
};

export const getClickedId = () => {
    var state = getState();
    setClickedId(null);
    return state.id;
};

export const getLastChecked = () => {
    var state = getState();
    return state.lastChecked;
};

export const getRequestId = () => {
    var state = getState();
    return state.requestId;
};

export const getParamsWithLastChecked = () => {
    var state = getState();
    if (
        !state.lastChecked ||
        state.filterScenarios.length === 0 ||
        !state.filterScenarios[0].filters
    ) {
        return null;
    }
    state.filterScenarios.forEach(fs => {
        fs.filters.push({
            label: "Created At",
            data: "vendor_offer_sets.created_at",
            mode: "interval",
            value: {
                min: moment()
                    .subtract(20, "years")
                    .format("YYYY-MM-DD HH:mm:ss"),
                max: state.lastChecked
            },
            type: "date"
        });
    });
    state.meta.length = 1;
    let activeScenario = state.filterScenarios.find(fs => fs.active);
    var params = {
        internalFilters: activeScenario
            ? activeScenario.filters
            : state.filterScenarios[0].filters, // TODO add logic for other scenarios as well
        meta: state.meta
    };
    return params;
};

export const getParams = () => {
    var state = getState();
    if (
        state.filterScenarios.length === 0 ||
        !state.filterScenarios[0].filters
    ) {
        return null;
    }
    state.meta.length = 1;
    let activeScenario = state.filterScenarios.find(fs => fs.active);
    var params = {
        internalFilters: activeScenario
            ? activeScenario.filters
            : state.filterScenarios[0].filters,
        meta: state.meta
    };
    return params;
};

export const setFilters = (filters, initial) => {
    var state = getState();
    filters.meta.search = filters.meta.search || "";
    state.columns = [];
    initial.template.columns.forEach(c => {
        let fc = filters.columns.find(co => co.name === c.name);
        let column = fc
            ? fc
            : {
                  name: c.name,
                  meta: DEFAULT_COLUMN_META
              };
        state.columns.push(column);
    });
    state.apiVersion = initial.apiVersion;
    state.template = state.template || initial.template.style;
    state.filterScenarios = filters.filterScenarios;
    state.meta = filters.meta;
    state.id = initial.id;
    state.lastChecked = filters.lastChecked || null;
    setState(state);
};

export const setClickedId = (id) => {
    var state = getState();
    state.id = id;
    setState(state);
};

export const setSort = (column, type) => {
    var state = getState();
    if (!state) {
        return;
    }
    state.columns.forEach(c => {
        c.meta.sort = c.name === column ? type : null;
    });
    state.meta.sort = true;
    setState(state);
};

export const resetScenarios = () => {
    var state = getState();
    state.filterScenarios = [];
    setState(state);
};

export default {
    init,
    hasState,
    setDefaultState,
    getState,
    setState,
    getFilters,
    setFilters,
    getParamsWithLastChecked,
    getParams,
    getLastChecked,
    setSort,
    getRequestId,
    resetScenarios,
    setClickedId,
    getClickedId,
    getTableId
};
