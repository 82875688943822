<template>
  <span>
     <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <button
              class="button"
              v-bind="attrs"
              v-on="on"
            >
              <i class="fas fa-list"></i>
            </button>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in filters" :key="index">
              <v-list-item-title class="item-list" @click="setState(item)">
                {{ item.oldName }}
                <i
                  v-if="info.sf == item.id"
                  class="fas fa-check"
                  style="color: #48c774"
                ></i>
              </v-list-item-title>
              <button class="info-btn" v-if="item.id" text @click="showFilter(item.id)">
                <i class="fas fa-eye"></i>
              </button>
              <button class="info-btn" v-if="item.id && item.user_id == currentUser.id" text @click="showConfirmSet= true;selectedDeleteFilter = item.id">
                <i class="fas fa-times"></i>
              </button>
            </v-list-item>
          </v-list>
        </v-menu>
    <button class="button" v-on:click="showHideColumns()">
      <i class="fa" :class="selectDeselectIcon" aria-hidden="true"></i>
    </button>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @keydown.esc="dialog = false"
      @keydown.enter="store('store')"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          class="button"
          @click="newFilter()"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Filter
        </button>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">New Filter</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-form
                @submit.prevent
                ref="requestForm"
                v-model="validRequestData"
                :key="formKey"
              >
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      label="Name"
                      v-model="requestData.name"
                      prepend-icon="info"
                      :rules="[validations.required()]"
                      :error-messages="error"
                      @input="clearValidation()"
                      :key="formKey"
                    ></v-text-field>
                  </v-col>

                  <template v-for="field in filtersFields">
                    <v-col
                      :key="field.key"
                      v-if="field.type === 'company_contact'"
                      cols="12"
                      sm="12"
                    >
                      <keep-alive>
                        <v-autocomplete
                          label="Company Contact"
                          placeholder="Start typing to Search"
                          prepend-icon="person"
                          v-bind:value="value"
                          :loading="isItemLoading"
                          :items="items"
                          :search-input.sync="searchValue"
                          :item-text="formatText"
                          v-on:input="companyContactSelect"
                          return-object
                        >
                        </v-autocomplete>
                      </keep-alive>
                    </v-col>
                    <v-col
                      :key="field.key"
                      v-if="field.type === 'date'"
                      cols="12"
                      sm="4"
                    >
                      <TPDatePicker
                        :label="field.label"
                        v-model="requestData['fields'][field.key]"
                        :rules="field.required ? [validations.required()] : []"
                        :key="formKey"
                      ></TPDatePicker>
                    </v-col>
                    <v-col
                      :key="field.key"
                      v-if="field.type === 'text'"
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        :label="field.label"
                        v-model="requestData['fields'][field.key]"
                        :rules="field.required ? [validations.required()] : []"
                        :key="formKey"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-col cols="12">
                    <v-textarea
                      name="input-7-1"
                      label="Notes"
                      prepend-icon="mdi-text"
                      v-model="requestData.notes"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                      v-model="requestData.visibility"
                      prepend-icon="visibility"
                      label="Public"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" v-if="data.user_id == currentUser.id || !data.user_id" text v-on:click="store('store')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
       <TPConfirm
            v-model="showConfirmSet"
            content="All information about this filter will be lost."
            @confirm="deleteFilter(selectedDeleteFilter)"
        ></TPConfirm>
  </span>
</template>
<script>
import moment from "moment";
import { debounce } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import TPConfirm from "@/views/partials/dialogs/Confirm.vue";
import { mapGetters } from "vuex";
import {
  GET_USER_FILTERS,
  DELETE_USER_FILTERS,
  STORE_USER_FILTER,
  UPDATE_USER_FILTER,
} from "@/store/userFilter.module";
import validations from "@/views/partials/form/Validations.vue";
import TPDatePicker from "@/views/partials/form/DatePicker.vue";
import { SEARCH_COMPANY_CONTACTS } from "@/store/companyContact.module";
import TableService from "@/common/table.service";

export default {
  components: {
    TPDatePicker,
    TPConfirm
  },
  mounted() {
    this.loadFilters();
    this.setInitialRequestData();
    this.setSelectDeselectIcon();
  },
  props: ["filtersFields", "info"],
  data() {
    return {
      items: [],
      displayFields: ["name", "email", "company_name"],
      dialog: false,
      value: null,
      validations: validations,
      validRequestData: false,
      requestData: { fields: {}, name: "", note: "", type: "", visibility: 0 },
      formKey: 0,
      filters: [],
      selectChange: 0,
      searchValue: null,
      selectedFilter: null,
      isItemLoading: false,
      showConfirmSet:false,
      selectedDeleteFilter:null,
      data:{},
      error: "",
      selectDeselectIcon: "fa-eye-slash",
    };
  },
  watch: {
    searchValue() {
      this.debouncedSearch();
    },
  },
  computed: {
    ...mapGetters(["companyContacts","currentUser"]),
  },
  methods: {
    setSelectDeselectIcon() {
      let state = TableService.getState();
      if (state.columns.filter((x) => x.meta.visible === true).length) {
        this.selectDeselectIcon = "fa-eye-slash";
      } else {
        this.selectDeselectIcon = "fa-eye";
      }
    },
    setFilter() {

    },
    deleteFilter(id) {
      //need to add confirm and forget to remove if not you filter
      this.$store.dispatch(DELETE_USER_FILTERS, id).then((data) => {
        this.loadFilters();
      });
    },
    loadFilters() {
      this.$store
        .dispatch(GET_USER_FILTERS, TableService.getTableId())
        .then((data) => {

          data.forEach((x) => {
            x.oldName = x.name;
            this.filters.push(x);
          });
        });
    },
    showFilter(id) {
      this.selectedFilter = id;
      this.requestData = this.filters.find((x) => x.id === id);
      this.dialog = true;

      if (this.requestData["fields"].hasOwnProperty("company_contact_id") && this.requestData["company_contact"]) {
        this.items.push(this.requestData["company_contact"]);
        this.value = this.requestData["company_contact"];
      }
      this.formKey += 1;
    },
    setInitialRequestData() {
      this.requestData = {
        name: "",
        visibility: 0,
        fields: {},
        notes: null,
        type: TableService.getTableId(),
        filters: {
          columns: [],
          filterScenarios: [],
          meta: {},
        },
      };
      this.filtersFields.forEach(
        (x) => (this.requestData.fields[x.key] = x.defaultValue)
      );
    },
    process(type) {
      this.requestData.filters = TableService.getFilters();
      this.requestData.filters.lastChecked = moment().format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.$refs.requestForm.validate();

      if (!this.validRequestData) {
        return;
      }

      this.$emit("processing");

      var dispatchUrl = !this.selectedFilter ? STORE_USER_FILTER : UPDATE_USER_FILTER;
      var params = !this.selectedFilter
        ? this.requestData
        : {
            id: this.selectedFilter,
            params: this.requestData,
          };
      this.$store
        .dispatch(dispatchUrl, params)
        .then((data) => {
          this.$store.dispatch(SHOW_SNACK, {
            message: "Filter stored successfuly.",
          });
          if (!this.selectedFilter) {
            this.setInitialRequestData();
            this.$refs.requestForm.resetValidation();
          }
          this.selectChange += 1;
          this.loadFilters();
          this.$emit("processed");
          this.dialog = false;
          this.info.sf = data.id;
        })
        .catch((error) => {
          this.error =
            Object.keys(error.errors).length > 0 &&
            error.errors[Object.keys(error.errors)[0]].length > 0
              ? error.errors[Object.keys(error.errors)[0]][0]
              : "";
        })
        .finally(() => {});
    },
    store() {
      this.process("store");
    },
    update() {
      this.process("update");
    },
    setState(e) {
      this.info.sf = e.id;
      this.data = e;
      let state = TableService.getState();
      let newState = this.filters.find((x) => x.id === e.id).filters;
      newState.apiVersion = state.apiVersion;
      newState.template = state.template;
      newState.meta.search = newState.meta.search?newState.meta.search:"";
      this.$emit("setState", newState);
    },
    showHideColumns() {
      let show = true;
      let state = TableService.getState();
      if (state.columns.filter((x) => x.meta.visible === true).length) {
        show = false;
        this.selectDeselectIcon = "fa-eye-slash";
      } else {
        show = true;
        this.selectDeselectIcon = "fa-eye";
      }

      state.columns.map((x) => (x.meta.visible = show));
      state.meta.search = "";
      this.$emit("setState", state);
    },
    clearValidation() {
      this.error = "";
      this.$refs.requestForm.resetValidation();
    },
    search() {
      if (
        !this.searchValue ||
        this.searchValue.length < 2 ||
        this.searchValue === this.formatText(this.value)
      ) {
        this.isItemLoading = false;
        return;
      }
      this.$store
        .dispatch(SEARCH_COMPANY_CONTACTS, this.searchValue)
        .then((x) => (this.items = x))
        .finally(() => {
          this.isItemLoading = false;
        });
    },
    companyContactSelect(e) {
      this.value = e;
      this.requestData.fields["company_contact_id"] = e.id;
    },
    debouncedSearch: debounce(function () {
      this.search();
    }, 500),
    formatText(i) {
      if (!i) {
        return;
      }

      var displayArray = [];
      this.displayFields.forEach((field) => {
        displayArray.push(i[field]);
      });
      return displayArray.join(", ");
    },
    newFilter() {
      this.setInitialRequestData();
      this.formKey += 1;
      this.searchValue = null;
      this.value = null;
      this.selectedFilter = null;
      this.data = {};
    },
  },
};
</script>

<style scoped>
.tag:not(body).is-danger {
  background-color: none;
}
</style>
