<script>
export default {
    checkIsValid(category, value) {
        const rules = this.getRuleFunctions(category);
        let isValid = true;

        rules.forEach(checkFunc => {
            const checkResult = checkFunc(value);
            if (checkResult !== true) {
                isValid = checkResult;
            }
        });

        return isValid;
    },
    getRuleFunctions(category,quick) {
        let rules = category.validation_rules;
        let functionArray = [];
        rules.forEach(rule => {
            if (typeof this[rule.rule] === "function") {
                functionArray.push(this[rule.rule](rule.var.value));
            } else {
                console.error(
                    `Rule function of type ${rule.rule} does not exists.`
                );
            }
        });
        if (category.tag_value_type === "numeric" && !quick) {
            functionArray.push(this.numeric());
        }

        return functionArray;
    },
    numeric() {
        return v => {
            return v && v.toString().match(/^[0-9,.]+$/) !== null?true:"Item must be a number";
        };
    },
    email() {
        return v => {
            return v && v.toString().match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/) !== null?true:"Item must be a email";
        };
    },
    required() {
        return v => {
            return !!v || "Item is required";
        };
    },
    min(c) {
        return v => {
            return (
               v && v.length > parseInt(c)?true: `Item must be a minimum of ${c} symbols.`
            );
        };
    },
    minNumber(c) {
        return v => {
            return (
               v && v >= parseInt(c)?true: `Item must be a minimum ${c}.`
            );
        };
    },
    integer() {
        return v => {
            return (
               v && v == parseInt(v)?true: `Item must be a integer.`
            );
        };
    }
};
</script>
