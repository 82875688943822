<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title class="headline">Are you sure?</v-card-title>

                <v-card-text>
                    {{ content }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" text @click="decline">
                        No
                    </v-btn>

                    <v-btn
                        ref="ConfirmBtn"
                        color="success"
                        text
                        @click="confirm"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    props: {
        content: {
            type: String,
            default: "Continue with this action?"
        },
        value: {
            type: Boolean
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
            if (this.dialog) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs.ConfirmBtn.$el.focus();
                    }, 10);
                });
            }
        },
        dialog() {
            this.$emit("input", this.dialog);
        }
    },
    data() {
        return {
            dialog: false
        };
    },
    methods: {
        confirm() {
            this.$emit("confirm");
            this.dialog = false;
        },
        decline() {
            this.$emit("decline");
            this.dialog = false;
        }
    }
};
</script>
