<template>
  <v-card :disabled="isProcessing" :loading="isProcessing">
    <template v-slot:progress>
      <v-progress-linear
        absolute
        color="success"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>

    <vue-table
      :path="getPath()"
      :id="tableId"
      @export-excel="showStartExportNotification"
      @export-excel-done="showExportNotification"
      :errorHandler="tableErrorHandler"
      @ready="tableReadyHandler"
      @show="showHandler"
      @clone="clone"
      @printView="printView"
      @download="download"
      @downloadRaw="downloadRaw"
      @showDetails="showDetails"
      ref="tableInfo"
      @reset="tableResetHandler"
      @fetching="tableFetchingHandler"
      @fetched="tableFetchedHandler"
      :key="tableKey"
      @clicked="clicked"
    >
      <template v-slot:all_columns>
        <TableFilters
          @setState="setState"
          v-if="requestLoaded"
          :info="info"
          :filtersFields="filtersFields"
        ></TableFilters>
      </template>
      <template v-slot:id="{ row, column }">
        <span v-if="lastSelectedRow(row.id)" ref="lastRow">
          {{ row[column.name] }}
        </span>
        <span v-if="!lastSelectedRow(row.id)"> {{ row[column.name] }}</span>
      </template>
      <template v-slot:is_active="{ row, column }">
        <v-switch
          v-model="row.is_active"
          :true-value="1"
          :false-value="0"
          @change="updateRow(row, column)"
          hide-details
          :key="tableSlotComponentsKey"
        ></v-switch>
      </template>
      <template v-slot:active="{ row, column }">
        <v-switch
          v-model="row.active"
          :true-value="1"
          :false-value="0"
          disabled
          @change="updateRow(row, column)"
          hide-details
          :key="tableSlotComponentsKey"
        ></v-switch>
      </template>
    </vue-table>
  </v-card>
</template>


<script>
import { VueTable } from "@enso-ui/tables/bulma";
import { SHOW_SNACK } from "@/store/snack.module";
import TableService from "@/common/table.service";
import TableFilters from "./Filters";

export default {
  components: {
    VueTable,
    TableFilters,
  },
  props: [
    "path",
    "tableId",
    "dbTable",
    "tableInit",
    "showParams",
    "filtersFields",
    "clickedDetails",
  ],
  data() {
    return {
      isProcessing: false,
      requestLoaded: false,
      info: { sf: 0 },
      showColumns: true,
      tableKey: 0,
      tableSlotComponentsKey: 0,
    };
  },
  beforeDestroy: function () {
    this.$el.removeEventListener("click", this.clickInfo);
  },
  mounted() {
    this.isProcessing = true;
    this.$el.addEventListener("click", this.clickInfo);
    TableService.init(this.tableId, this.dbTable);
    if (!TableService.hasState()) {
      this.$store.dispatch(this.tableInit).then((data) => {
        TableService.setDefaultState(data);
        this.isProcessing = false;
        this.requestLoaded = true;
      });
    } else {
      this.rowId = TableService.getClickedId();
      this.requestLoaded = true;
    }
  },
  methods: {
    showStartExportNotification() {
      this.$store.dispatch(SHOW_SNACK, {
        message: `Export started in the background. Please wait...`,
        timeout: 200000,
        type: "warning",
      });
    },
    showExportNotification(params) {
      this.$store.dispatch(SHOW_SNACK, {
        message: `Export successfull. Please click <a target="_blank" href="${params.data}">here</a> to download your file.`,
        timeout: 200000,
      });
    },
    tableErrorHandler(response) {
      console.error(response);
      this.handleException(response);
    },

    tableReadyHandler() {
      this.isProcessing = false;
    },
    tableResetHandler() {
      this.$emit("reset");
    },
    tableFetchedHandler() {
      this.tableSlotComponentsKey += 1;
      this.isProcessing = false;
    },
    tableFetchingHandler() {
      this.isProcessing = true;
    },
    showHandler(item) {
      if (!item && this.showParams.hasOwnProperty("key")) {
        return;
      }
      let id = parseInt(item[this.showParams.key]);
      TableService.setClickedId(item.id);
      this.$router.push({
        name: this.showParams.name,
        params: { id: id },
      });
    },
    clickInfo(event) {
      const parent = event.path.find((x) => x.nodeName === "TR");
      if (!parent || event.target.classList.contains("svg-inline--fa")) {
        return;
      }
      const target = parent.querySelector(".hidden-control");

      if (!event.target.classList.contains("hidden-control")) {
        if (target) {
          target.click();
        }
      }
    },
    lastSelectedRow(row) {
      if (row === this.rowId) {
        setTimeout((x) => {
          if (this.$refs.lastRow) {
            this.$refs.lastRow.scrollIntoViewIfNeeded(true);
            this.setColor("#00ca19", 100);
            this.setColor("", 300);
            this.setColor("#00ca19", 500);
            this.setColor("", 700);
            this.setColor("#00ca19", 900);
            this.setColor("", 1500, true);
          }
        }, 100);
        return true;
      }
      return false;
    },
    setColor(color, timeout, remove) {
      setTimeout((f) => {
        if (this.$refs.lastRow) {
          this.$refs.lastRow.parentNode.parentNode.parentNode.style.background = color;
          if (remove) {
            this.rowId = null;
          }
        }
      }, timeout);
    },

    updateRow(row, column) {
      this.$emit("updateRow", row);
    },
    setState(state) {
      TableService.setState(state);
      this.tableKey += 1;
    },

    changeDone(show) {
      console.log(show);
    },
    clicked(e) {
      let info = this.clickedDetails[e.column.data];
      let table = `VueTable_${info.targetTable}_preferences`;
      let state = TableService.getState(table);
      state.filterScenarios = [
        {
          active: true,
          edit: true,
          filters: [
            {
              component: "string",
              data: info.targetColumn,
              label: "",
              mode: "full",
              type: "string",
              value: e.row[info.column],
            },
          ],
          name: "find by " + info.column + " " + e.row[info.column],
        },
      ];
      TableService.setState(state, table);
      this.$router.push({ name: info.url });
    },
    clone(row) {
      this.$emit("clone", row);
    },
    printView(row) {
      this.$emit("printView", row);
    },
    download(row) {
      this.$emit("download", row);
    },
    downloadRaw(row) {
      this.$emit("downloadRaw", row);
    },
    showDetails(row) {
      TableService.setClickedId(row.id);

      this.$emit("showDetails", row);
    },
    getPath() {
      if (Object.keys(this.$route.query).length > 0) {
        return (
          this.path + "?" + new URLSearchParams(this.$route.query).toString()
        );
      }

      return this.path;
    },
  },
};
</script>
